import { createSelector } from 'reselect'

export const selectInstitutionConstraintsByInstitution = createSelector(
  state => state.institutionConstraints.constraints,
  constraints => {
    const constraintsByInstitution = {}

    constraints.forEach(c => {
      if (c.enable) {
        if (!constraintsByInstitution[c.institution.id]) {
          constraintsByInstitution[c.institution.id] = []
        }

        constraintsByInstitution[c.institution.id].push(c)
      }
    })

    return constraintsByInstitution
  }
)
