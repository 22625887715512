import React, { useState, useEffect } from 'react'

import PersonnalInformationsForm from './PersonnalInformationsForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { Card, Tooltip } from 'antd'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../reducers/Dispatchers'

import '../../../assets/profile-page.scss'

const ACADEMIC_DATA_TITLE = 'My school contact information'
const PRIVATE_DATA_TITLE = 'My personal contact information'
const TIPS_TEXT = 'Information necessary for the preparation of your internship and contact in case of problems.\n' +
  'Possibility of contact for recruitment purposes if desired.'

const PersonnalInformations = (props) => {
  const [schoolMail, setSchoolMail] = useState('')
  const [schoolData, setSchoolData] = useState({})
  const [schoolPhoneNumber, setSchoolPhoneNumber] = useState('')

  /**
   * Set the school information in local state when the Component is loaded
   */
  useEffect(() => {
    if (props.getUser) {
      setSchoolMail(props.getUser.email)
      setSchoolPhoneNumber(props.getUser.student ? props.getUser.student.phoneNumber : 'unknow')
    }
  }, [])
  useEffect(() => {
    if (props.getStudentInformations) {
      const sectionId = Object.keys(props.getStudentInformations)[0]

      if (sectionId) {
        setSchoolData(props.getStudentInformations[sectionId])
      }
    }
  }, [props.getStudentInformations])

  const renderSchoolAddress = () => {
    const addressComponents = [
      schoolData.schoolAddress,
      schoolData.schoolCity,
      schoolData.zipCode,
      schoolData.schoolCountryCode
    ]
    let schoolAddress = ''

    addressComponents.forEach(item => {
      if (item) {
        schoolAddress = schoolAddress === ''
          ? schoolAddress + item
          : schoolAddress + ', ' + item
      }
    })

    return schoolAddress
  }
  /**
   * Render the content of the section with the informations linked to the student school
   *
   * @returns React grape of the school section
   */
  const renderSchoolInformation = () => {
    return (
      <div>
        <div className='inner-margin'>
          <Card
            type='inner'
            title={[<FontAwesomeIcon key={0} icon='school' />, ' ', props.t('Address')]}
          >
            {renderSchoolAddress()}
          </Card>
        </div>
        <div className='inner-margin'>
          <Card
            type='inner'
            title={[<FontAwesomeIcon key={1} icon='at' />, ' ', props.t('Email')]}
          >
            {schoolMail}
          </Card>
        </div>
        <Card
          type='inner'
          title={[<FontAwesomeIcon key={2} icon='phone' />, ' ', props.t('Phone number')]}
        >
          {schoolPhoneNumber}
        </Card>
      </div>
    )
  }

  return (
    <div className='profile-page-personnal'>
      <div className='profile-page-card-div'>
        <Card
          title={props.t(ACADEMIC_DATA_TITLE)}
        >
          {renderSchoolInformation()}
        </Card>
      </div>

      <div className='profile-page-card-div'>
        <Card
          title={[props.t(PRIVATE_DATA_TITLE), ' ',
            <Tooltip key='personal_section_tooltip' placement='right' title={props.t(TIPS_TEXT)}>
              <FontAwesomeIcon icon={faQuestionCircle} color='#1890ff' />
            </Tooltip>
          ]}
        >
          <PersonnalInformationsForm />
        </Card>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonnalInformations)
