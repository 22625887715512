import { ROLE_NURSE, ROLE_OBSERVER, ROLE_SUPERVISOR, ROLE_HOSPITAL_ADMIN, ROLE_ENCODER, ROLE_STUDENT } from './constants'
import { isArray } from 'lodash'

export const isNurse = roles => isArray(roles) && roles.includes(ROLE_NURSE)

export const isObserver = roles => isArray(roles) && roles.includes(ROLE_OBSERVER)

export const isSupervisor = roles => isArray(roles) && roles.includes(ROLE_SUPERVISOR)

export const isEncoder = roles => isArray(roles) && roles.includes(ROLE_ENCODER)

export const isHospitalAdmin = roles => isArray(roles) && roles.includes(ROLE_HOSPITAL_ADMIN)

export const isStudent = roles => isArray(roles) && roles.includes(ROLE_STUDENT)

export default { isNurse, isObserver, isEncoder, isSupervisor }
