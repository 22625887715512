import React, { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { COLORS_PALETTE } from '../../config/colors'

const ICON_SIZE_DEFAULT = { height: '20px', width: '20px' }

const IconLabel = ({ fontSize, icon, padding = 'pdl-8', text }) => {
  const fontSizeClass = useMemo(() => {
    let size = 'ft-reg'
    
    if (fontSize === 'small') {
      size = 'ft-sm'
    } else if (fontSize === 'large') {
      size = 'ft-l'
    } else if (fontSize === 'larger') {
      size = 'ft-lg'
    }
    
    return size
  }, [fontSize])

  return (
    <div className={`flex-row ${padding}`}>
      <div style={ICON_SIZE_DEFAULT}> <FontAwesomeIcon icon={icon} color={COLORS_PALETTE.SECOND}/> </div>
      <span className={`${fontSizeClass} ml-8`}> {text} </span>
    </div>
  )
}

export default IconLabel
