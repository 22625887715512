import React from 'react'

import { Select } from 'antd'

/**
 * @typedef {Object} EntityOption
 * @property {string} label The label to display in the <Option>.
 * @property {string | number | boolean} value The value that will be selected when the <Option> is selected.
 * @property {string | undefined} tooltip A text that will be displayed on mouse hover.
 */

/**
 * Returns a <Select> component constructed with the array of formatted entities (@type {EntityOption}).
 * @param {Object} params
 * @param {EntityOption[]} params.entityOptions The entities used for the <Options> inside the <Select> component.
 * @param {EntityOption[]} params.preSelected The entities to display when the component mounts.
 * @param {Object} [params.style] Facultative additional style
 * @param {(value: any) => void} params.onChange The callback to invoke when the list of selected entities change.
 *
 * @returns {JSX.Element}
 */
const EntitiesSelector = ({ onChange, entityOptions, preSelected = [], style = {} }) => {
  return (
    <Select
      style={{ ...style }}
      mode='multiple'
      allowClear
      onChange={onChange}
      value={preSelected}
      optionFilterProp='label'
      filterOption={(input, option) => {
        const { label, tooltip } = option.props.infos

        return (
          (label && label.toLowerCase().includes(input.toLowerCase())) ||
          (tooltip && tooltip.toLowerCase().includes(input.toLowerCase()))
        )
      }}
    >
      {entityOptions.map(entityOption => (
        <Select.Option
          key={'entity_' + entityOption.value}
          title={entityOption.tooltip}
          value={entityOption.value}
          infos={entityOption}
        >
          {entityOption.label}
        </Select.Option>
      ))}
    </Select>
  )
}

export default EntitiesSelector
