import React, { useContext, useMemo } from 'react'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import EntitiesSelector from './EntitiesSelector'

const SectionsSelector = ({ preSelected, style = {}, onChange }) => {
  const { sections } = useContext(GlobalContext)

  const sectionOptions = useMemo(() => sections.map(section => ({
    label: section.abbreviation,
    value: section.id,
    tooltip: section.name
  })), [sections])

  return (
    <EntitiesSelector
      onChange={onChange}
      entityOptions={sectionOptions}
      preSelected={preSelected}
      style={{ ...style }}
    />
  )
}

export default SectionsSelector
