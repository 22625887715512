import { downloadFile, requestWithPromise } from '..'
import { DELETE, GET, PATCH, makeQueryString } from '../apiHelper'
import { TYPES } from '../entities/internshipDocument'

export const getInternshipConvention = (internship, user) => {
  const parameters = { type: TYPES.CONVENTION, orderBy: 'updatedAt', orderDirection: 'desc' }

  return requestWithPromise(
    `/api/internships/${internship.id}/documents${makeQueryString(parameters)}`,
    GET,
    null,
    user
  ).then(json => json?.data[0] ?? null)
}

export const downloadInternshipDocument = (user, document) => {
  return downloadFile(`/internship/document/download/${document.id}`, document.originalName, user)
}

export const deleteInternshipDocument = (user, document) => {
  return requestWithPromise(
    `/api/InternshipDocument/${document.id}`,
    DELETE,
    null,
    user
  )
}

export const updateInternshipDocument = (document, user) => {
  return requestWithPromise(
    `/api/InternshipDocument/${document.id}`,
    PATCH,
    { locked: true },
    user
  )
}
