import React, { useEffect, useState } from 'react'
import { Switch } from 'antd'
import orderBy from 'lodash/orderBy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
import '../../../assets/message-box.scss'

const LOCAL_STORAGE_SETTINGS_KEY = 'interneoMessageBoxSettings'

const MessageBox = props => {
  const [isSchoolUser, setIsSchoolUser] = useState(false)
  const [messagesList, setMessagesList] = useState([])
  const [showOnlyUnreadMessages, setShowOnlyUnreadMessages] = useState(false)
  const [sortDateAsc, setSortDateAsc] = useState(true)

  useEffect(() => {
    // read the settings
    let settings = global.localStorage.getItem(LOCAL_STORAGE_SETTINGS_KEY)
    if (settings) {
      try {
        settings = JSON.parse(settings)
        if (settings.showOnlyUnreadMessages !== undefined) {
          setShowOnlyUnreadMessages(settings.showOnlyUnreadMessages)
        }
        if (settings.sortDateAsc !== undefined) {
          setSortDateAsc(settings.sortDateAsc)
        }
      } catch (err) {
        // nothing here
      }
    }

    if (props.getUser.context === 'SCHOOL') {
      setIsSchoolUser(true)
    }
  }, [])

  useEffect(() => {
    let list = [...props.getInternshipMessagesList.internshipsWithMessages]
    if (showOnlyUnreadMessages) {
      list = list.filter(item => item.unreadMessages > 0)
    }
    if (!sortDateAsc) {
      list = orderBy(list, ['internship'], ['desc'])
    }
    setMessagesList(list)
  }, [props.getInternshipMessagesList.internshipsWithMessages])

  useEffect(() => {
    let list = [...props.getInternshipMessagesList.internshipsWithMessages]
    if (showOnlyUnreadMessages) {
      list = list.filter(item => item.unreadMessages > 0)
    }
    if (!sortDateAsc) {
      list = orderBy(list, ['internship'], ['desc'])
    }
    setMessagesList(list)
    saveSettingsToLocalStorage()
  }, [showOnlyUnreadMessages])

  useEffect(() => {
    let listToSort = [...messagesList]
    listToSort = orderBy(listToSort, ['internship'], [sortDateAsc ? 'asc' : 'desc'])
    setMessagesList(listToSort)
    saveSettingsToLocalStorage()
  }, [sortDateAsc])

  const saveSettingsToLocalStorage = () => {
    const settings = { showOnlyUnreadMessages, sortDateAsc }
    global.localStorage.setItem(LOCAL_STORAGE_SETTINGS_KEY, JSON.stringify(settings))
  }

  const handleMessageClick = message => {
    props.setInternshipForChat({
      id: message.internship,
      startDate: message.startDate,
      endDate: message.endDate,
      studentName: message.studentName,
      school: message.school,
      institution: message.institution
    })
  }

  const handleSwitchChange = () => {
    setShowOnlyUnreadMessages(!showOnlyUnreadMessages)
  }

  const handleSortDirection = () => {
    setSortDateAsc(!sortDateAsc)
  }

  return (
    <div className={`message-box ${props.getMessagesListIsDisplayed ? 'displayed' : ''}`}>
      <div className='message-box-header' onClick={() => { props.toggleMessageList() }}>
        <div className='title'>
          {props.t('Messaging')}
          {props.getUnreadMessages > 0 && <span className='message-count-circle'>{props.getUnreadMessages}</span>}
        </div>
        <span>
          <FontAwesomeIcon icon={faChevronDown} />
        </span>
      </div>
      <div className='filter-box-wrapper'>
        <div className='option-group'>
          <label>{props.t('Show only unread messages')}</label>
          <Switch checked={showOnlyUnreadMessages} onChange={handleSwitchChange} />
        </div>
        <div className='option-group'>
          <label>{props.t('Sorting by date')}</label>
          <button className={`sort-button ${sortDateAsc ? 'up' : ''}`} type='button' onClick={handleSortDirection}>
            <FontAwesomeIcon icon='arrow-down' />
          </button>
        </div>
      </div>
      <div className='separation-line' />
      {messagesList.length === 0 && (
        <div className='no-message-notification'>
          {showOnlyUnreadMessages ? props.t('You don\'t have unread messages') : props.t('You don\'t have messages')}
        </div>
      )}
      {messagesList.length > 0 && (
        <ul className='messages-list'>
          {messagesList.map((item, index) => (
            <li key={`${item.internship}-${index}`} onClick={() =>  handleMessageClick(item)}>
              <div className={`message-item ${item.unreadMessages ? 'new-messages' : ''}`}>
                <div className='message-text'>
                  <div className='inner-message-row'>
                    <span className='message-icon'>
                      {isSchoolUser ? <FontAwesomeIcon icon='clinic-medical' /> : <FontAwesomeIcon icon='school' />}
                    </span>
                    <span className='inner-message-text'>{isSchoolUser ? item.institution : item.school}</span>
                  </div>
                  <div className='inner-message-row'>
                    <span className='message-icon'>
                      <FontAwesomeIcon icon='graduation-cap' />
                    </span>
                    <span className='inner-message-text'>{item.studentName === null ? props.t('Without student') : item.studentName}</span>
                  </div>
                  <div className='inner-message-row'>
                    <span className='message-icon'>
                      <FontAwesomeIcon icon='calendar' />
                    </span>
                    <span className='inner-message-text'>{item.startDate} - {item.endDate}</span>
                  </div>
                </div>
                {item.unreadMessages > 0 && (
                  <div className='messages-count'>
                    <span className='message-count-circle'>{item.unreadMessages}</span>
                  </div>
                )}
              </div>
            </li>
          ))}

        </ul>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBox)
