import React, { useContext, useEffect, useRef, useState } from 'react'

import InternshipsTable from '../../Components/shared/InternshipsManager/InternshipsTable'
import InternshipsHeader, { PRECISION_YEAR } from '../../Components/shared/InternshipsManager/InternshipsHeader'
import { bindActionCreators } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { getAppStates } from '../../reducers/AppStates/actions'
import { DATE_WITHOUT_TIME, DIRECTION_DESC, internshipStates, ORDER_BY_CREATED_AT } from '../../utils/constants'
import { getUser } from '../../reducers/UserReducer'
import InternshipsManagerProvider from '../../Context/InternshipsManager/InternshipsManagerProvider'
import StudentInfo from '../../Components/shared/StudentInfo'
import InternshipInfo from '../../Components/shared/InternshipInfo'
import { startCheckingForMessages, stopCheckingForNewMessages } from '../../reducers/NewMessagesReducer'
import { deleteInternship, getAllInternships } from '../../utils/api/internship'
import { onError, onSuccess, purgeMetadata } from '../../utils/apiHelper'
import InternshipValidation from '../../Components/shared/ShiftsManager/InternshipValidation'
import { getState } from '../../reducers/InternshipsManagerReducer/actions'
import { INTERNSHIPS_MANAGER } from '../../reducers/InternshipsManagerReducer/actionsType'
import EarlyDateModal from '../../Components/shared/Modals/EarlyDateModal'
import { getTranslate } from 'react-localize-redux'
import { fetchQuotasTree } from '../../reducers/QuotasReducer/actions'
import { getInternshipsQuotas } from '../../utils/api/quota'
import InternshipRejection from '../../Components/shared/InternshipsManager/InternshipRejection'
import { APP_STATES } from '../../reducers/AppStates/actionsType'
import useLocalStorage from '../../hooks/UseLocalStorage'
import { GlobalFiltersContext } from '../../Providers/GlobalFiltersProvider'
import SESSION_STORAGE_KEYS from './InternshipManagerViewFilters'

const { SET_DATE } = APP_STATES
const {
  SEARCH,
  STATES,
  SCHOOLS,
  INSTITUTIONS,
  SECTIONS,
  ARE_STARTED_EXCLUDED,
  ORDER_BY,
  SORT_DIRECTION,
  PRECISION
} = SESSION_STORAGE_KEYS.INTERNSHIPS_MANAGER

const DEFAULT_NUMBER_OF_WEEKS = 24
const DEFAULT_META_DATA = {
  page: 1,
  last: 0,
  perPage: 10,
  totalPages: 1,
  total: 0
}
const TIME_BEFORE_SEARCH = 200
const INTERNSHIPS_NUMBER = 10

const mapStateToProps = state => ({
  currentDate: getAppStates(state).date,
  refusedInternship: getState(state).refusedInternship,
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const mapDispatchToProps = dispatch => ({
  startCheckingForMessages: bindActionCreators(startCheckingForMessages, dispatch),
  stopCheckingForNewMessages: bindActionCreators(stopCheckingForNewMessages, dispatch),
  fetchQuotasTree: bindActionCreators(fetchQuotasTree, dispatch)
})

const InternshipsManagerView = props => {
  const { internshipSearch } = useContext(GlobalFiltersContext)

  const [addMode, setAddMode] = useState(false)
  const [displayEarlyModal, setDisplayEarlyModal] = useState(false)
  const [internshipToValidate, setInternshipToValidate] = useState(null)
  const [internships, setInternships] = useState([])
  const [metadata, setMetadata] = useState(DEFAULT_META_DATA)
  const [page, setPage] = useState(1)
  const [quotas, setQuotas] = useState({})
  const [selectedInternship, setSelectedInternship] = useState(null)
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [weeksShownCount, setWeeksShowCount] = useState(DEFAULT_NUMBER_OF_WEEKS)
  const [loading, setLoading] = useState(false)

  const [search, setSearch] = useLocalStorage(SEARCH, '')
  const [states, setStates] = useLocalStorage(STATES, [])
  const [schools, setSchools] = useLocalStorage(SCHOOLS, [])
  const [institutions, setInstitutions] = useLocalStorage(INSTITUTIONS, [])
  const [sections, setSections] = useLocalStorage(SECTIONS, [])
  const [areStartedExcluded, setAreStartedExcluded] = useLocalStorage(ARE_STARTED_EXCLUDED, false)

  const [orderBy, setOrderBy] = useLocalStorage(ORDER_BY, ORDER_BY_CREATED_AT)
  const [sortDirection, setSortDirection] = useLocalStorage(SORT_DIRECTION, DIRECTION_DESC)
  const [precision, setPrecision] = useLocalStorage(PRECISION, PRECISION_YEAR)

  const dispatch = useDispatch()
  const fetchTimer = useRef()

  useEffect(() => {
    setSearch(internshipSearch)
  }, [internshipSearch, setSearch])

  useEffect(() => {
    if (props.user && typeof props.startCheckingForMessages === 'function') {
      props.startCheckingForMessages(props.user)
    }

    return () => {
      props.stopCheckingForNewMessages()
    }
  }, [props.user, props.startCheckingForMessages])

  useEffect(() => {
    if (props.user) {
      setLoading(true)

      const parameters = buildUrlParemeters({
        startDate: props.currentDate,
        weeks: weeksShownCount,
        search,
        states,
        schools,
        institutions,
        sections,
        areStartedExcluded,
        orderBy,
        sortDirection,
        page,
        perPage: INTERNSHIPS_NUMBER
      })

      postponeInternshipsFetch(parameters, props.user)
    }
  }, [
    props.user, page, search, states, schools, institutions, sections, areStartedExcluded,
    orderBy, sortDirection, weeksShownCount, props.currentDate
  ])

  const postponeInternshipsFetch = (urlParameters, user) => {
    if (typeof fetchTimer.current === 'number') {
      clearTimeout(fetchTimer.current)
    }

    fetchTimer.current = setTimeout(() => {
      fetchInternships(urlParameters, user)
    }, TIME_BEFORE_SEARCH)
  }

  const handleInternshipDeletion = internship => {
    deleteInternship(internship, props.user).then(json => {
      if (json?.data) {
        onSuccess(props.t('internships_manager.delete_internship_success'))
        refreshInternships()
      } else {
        onError(props.t('internships_manager.delete_internship_forbidden'))
      }
    })
  }

  const refreshInternships = () => {
    const parameters = buildUrlParemeters({
      startDate: props.currentDate,
      weeks: weeksShownCount,
      search,
      states,
      schools,
      institutions,
      sections,
      areStartedExcluded,
      orderBy,
      sortDirection,
      page,
      perPage: INTERNSHIPS_NUMBER
    })

    fetchInternships(parameters, props.user)
  }

  const refreshQuotas = customPrecision => {
    const endDate = props.currentDate.clone()
      .add(weeksShownCount - 1, 'week')
      .endOf('isoWeek')

    fetchQuotas(
      internships,
      props.currentDate.format(DATE_WITHOUT_TIME),
      endDate.format(DATE_WITHOUT_TIME),
      props.user,
      { precision: customPrecision ?? precision }
    )
  }

  const buildUrlParemeters = parameters => {
    const endDate = parameters.startDate.clone()
      .add(parameters.weeks - 1, 'week')
      .endOf('isoWeek')

    return {
      startDate: parameters.startDate.format(DATE_WITHOUT_TIME),
      endDate: endDate.format(DATE_WITHOUT_TIME),
      search: parameters.search,
      states: parameters.states,
      schools: parameters.schools,
      institutions: parameters.institutions,
      sections: parameters.sections,
      areStartedExcluded: parameters.areStartedExcluded,
      orderBy: parameters.orderBy,
      orderDirection: parameters.sortDirection,
      page: parameters.page,
      perPage: parameters.perPage
    }
  }

  const fetchInternships = (urlParameters, user) => {
    getAllInternships(user, urlParameters)
      .then(json => {
        if (json) {
          if (json.data) {
            setInternships(json.data)

            if (json.data.length > 0) {
              fetchQuotas(
                json.data,
                urlParameters.startDate,
                urlParameters.endDate,
                user,
                { precision: precision }
              )
            }
          }

          if (json.meta) {
            setMetadata(purgeMetadata(json.meta.pages))
          }
        }

        setLoading(false)
      })
  }

  const fetchQuotas = (internships, startDate, endDate, user, parameter = {}) => {
    getInternshipsQuotas(internships, startDate, endDate, user, parameter)
      .then(json => {
        if (json.data) {
          setQuotas(json.data)
        }
      })
  }

  const handleInternshipValidation = () => {
    const updatedInternships = internships.map(internship => {
      if (internship.id === internshipToValidate.id) {
        return { ...internship, state: internshipStates.SCHEDULE_VALIDATED }
      }

      return internship
    })

    setInternships(updatedInternships)
    setInternshipToValidate({ ...internshipToValidate, state: internshipStates.SCHEDULE_VALIDATED })
  }

  const handleInternshipInvalidation = newState => {
    const updatedInternships = internships.map(internship => {
      if (internship.id === internshipToValidate.id) {
        return { ...internship, state: newState }
      }

      return internship
    })

    setInternships(updatedInternships)
    setInternshipToValidate({ ...internshipToValidate, state: newState })
  }

  const handleInternshipRefused = () => {
    refreshInternships()
  }

  const handleRejectionClosing = () => {
    dispatch({
      type: INTERNSHIPS_MANAGER.SET_REFUSED_INTERNSHIP,
      payload: null
    })
  }

  const toggleAddMode = () => {
    setAddMode(!addMode)
  }

  const handleSearch = text => {
    setPage(1)
    setSearch(text)
  }

  const handleStates = array => {
    setPage(1)
    setStates(array)
  }

  const onSchoolSelection = array => {
    setPage(1)
    setSchools(array)
  }

  const onInstitutionSelection = array => {
    setPage(1)
    setInstitutions(array)
  }

  const onSectionSelection = array => {
    setPage(1)
    setSections(array)
  }

  const onStartedExclusion = boolean => {
    setPage(1)
    setAreStartedExcluded(boolean)
  }

  const handleWeeksShowChange = newWeeks => {
    setPage(1)
    setWeeksShowCount(newWeeks)
  }

  const handleDateChange = newDate => {
    setPage(1)
    dispatch({
      type: SET_DATE,
      payload: newDate
    })
  }

  const handleResetFilters = () => {
    setPage(1)
    setSearch('')
    setStates([])
    setSchools([])
    setInstitutions([])
    setSections([])
    setAreStartedExcluded(false)
    setOrderBy(ORDER_BY_CREATED_AT)
    setSortDirection(DIRECTION_DESC)
    setPrecision(PRECISION_YEAR)
    setWeeksShowCount(DEFAULT_NUMBER_OF_WEEKS)
  }

  return (
    <>
      <InternshipsHeader
        addMode={addMode}
        search={search}
        states={states}
        schools={schools}
        institutions={institutions}
        sections={sections}
        areStartedExcluded={areStartedExcluded}
        orderBy={orderBy}
        sortDirection={sortDirection}
        page={page}
        precision={precision}
        metadata={metadata}
        weeksShownCount={weeksShownCount}
        loadShiftsManager={props.loadShiftsManager}
        onDateChange={handleDateChange}
        onOrderByChange={setOrderBy}
        onPageChange={setPage}
        onPrecisionChange={newPrecision => {
          setPrecision(newPrecision)
          refreshQuotas(newPrecision)
        }}
        onSearchChange={handleSearch}
        onSortDirectionChange={setSortDirection}
        onStatesChange={handleStates}
        onSchoolSelection={onSchoolSelection}
        onInstitutionSelection={onInstitutionSelection}
        onSectionSelection={onSectionSelection}
        onStartedExclusion={onStartedExclusion}
        onWeeksShowChange={handleWeeksShowChange}
        toggleAddMode={toggleAddMode}
        onResetFilters={handleResetFilters}
      />
      <InternshipsManagerProvider
        addMode={addMode}
        internships={internships}
        quotas={quotas}
        weeksShownCount={weeksShownCount}
        onEarlyStartDate={setDisplayEarlyModal}
        onInternshipDelete={handleInternshipDeletion}
        onInternshipSelected={setSelectedInternship}
        onStudentSelected={setSelectedStudent}
        refreshInternships={refreshInternships}
        refreshQuotas={refreshQuotas}
        setInternshipToValidate={setInternshipToValidate}
      >
        <InternshipsTable loading={loading} />
      </InternshipsManagerProvider>
      <StudentInfo
        student={selectedStudent}
        onClose={() => setSelectedStudent(null)}
      />
      <InternshipInfo
        internship={selectedInternship}
        onClose={() => setSelectedInternship(null)}
      />
      {internshipToValidate && (
        <InternshipValidation
          internship={internshipToValidate}
          onClose={() => setInternshipToValidate(null)}
          onValidate={handleInternshipValidation}
          onInvalidate={handleInternshipInvalidation}
        />
      )}
      <InternshipRejection
        internship={props.refusedInternship}
        onRejection={handleInternshipRefused}
        onClose={handleRejectionClosing}
      />
      {displayEarlyModal && (
        <EarlyDateModal
          t={props.t}
          onVisibleChange={setDisplayEarlyModal}
          visible
        />
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InternshipsManagerView)
