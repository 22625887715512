import React from 'react'

import TooltipIcon from './TooltipIcon'

const ICON_CENTER = { alignItems: 'center' }

const TitleIcon = ({ title, tooltip }) => {
  return (
    <div className='flex-row' style={ICON_CENTER}>
      <div><span><b>{title}</b></span></div>
      &nbsp;
      <TooltipIcon title={tooltip} />
    </div>
  )
}

export default TitleIcon
