import React, { useEffect, useState, useRef } from 'react'

import PersonnalHeader from '../../Components/student/profilePage/PersonnalHeader'
import PersonnalInformations from '../../Components/student/profilePage/PersonnalInformations'
import PrivacyInformations from '../../Components/student/profilePage/PrivacyInformations'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { Row, Col } from 'antd'

const MIN_WINDOW_WIDTH = 900

const ProfilePageView = props => {
  const [spanSize, setSpanSize] = useState(12)
  const display = useRef(false)
  /**
   * Get all the missing student informations from the backend when the component is mount
   */
  useEffect(() => {
    const user = props.getUser

    if (user.student) {
      props.fetchStudentExtraInformations(user)

      if (user.student.profileImageName) {
        props.fetchStudentProfileImage(user.student.profileImageName, user)
      }
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const handleWindowResize = () => {
    if (!display.current && window.innerWidth <= MIN_WINDOW_WIDTH) {
      display.current = true
      setSpanSize(24)
    } else if (display.current && window.innerWidth > MIN_WINDOW_WIDTH) {
      display.current = false
      setSpanSize(12)
    }
  }

  return (
    <div className='profile-page flex-column'>
      <PersonnalHeader />
      <Row>
        <Col key='personnal-informations' span={spanSize}>
          <PersonnalInformations />
        </Col>,
        <Col key='privacy-informations' span={spanSize}>
          <PrivacyInformations />
        </Col>
      </Row>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePageView)
